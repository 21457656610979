///// Token logos /////////
import JadeImg from "./../../images/tokens/jade.png";
import HunnyImg from "./../../images/tokens/hunny.png";
import NemesisImg from "./../../images/tokens/nemesis.png";
import TabooImg from "./../../images/tokens/taboo.png";
import MidasImg from "./../../images/tokens/midas.png";
import MagnetImg from "./../../images/tokens/magnet.png";
import MaximizerImg from "./../../images/tokens/maximizer.png";
import PapaImg from "./../../images/tokens/papa.png";
import VikingsImg from "./../../images/tokens/vikings.png";
import KlimaImg from "./../../images/tokens/klima.png";

const TokenList = [
	{
		id: "jade",
		protocol: "Jade",
		chain_id: 56,
		tokens: [
			{
				token_contract: "0x7ad7242A99F21aa543F9650A56D141C57e4F6081",
				label: "JADE",
			},
			{
				token_contract: "0x94CEA04C51E7d3EC0a4A97Ac0C3B3c9254c2aD41",
				label: "sJADE",
				price_chart_url:
					"https://dexscreener.com/bsc/0x46503d91d7a41fcbdc250e84cee9d457d082d7b4",
			},
		],
		toPool: true,
		rebase: false,
		home_url: "https://jadeprotocol.io/",
		icon: JadeImg,
	},
	{
		id: "hunny",
		protocol: "HunnyDAO",
		chain_id: 56,
		tokens: [
			{
				token_contract: "0x9505dbD77DaCD1F6C89F101b98522D4b871d88C5",
				label: "LOVE",
			},
			{
				token_contract: "0x9f12cad130d40d40541cae8e3c295228769ad111",
				label: "KISS",
			},
			{
				token_contract: "0x67e248F9810D4D121ab2237Eb33D21f646011720",
				label: "KISS v2",
			},
			{
				token_contract: "0x153629b8ce84f5e6dd6044af779aa37adb431393",
				label: "HUG",
			},
		],
		toPool: true,
		rebase: true,
		home_url: "https://dao.hunny.finance/",
		icon: HunnyImg,
	},
	/////////////////
	{
		id: "magnet",
		protocol: "MagnetDAO",
		chain_id: 43114,
		tokens: [
			{
				token_contract: "0x1d60109178C48E4A937D8AB71699D8eBb6F7c5dE",
				label: "MAG",
			},
			{
				token_contract: "0x1EdA3EF892Ab5521F4FCd5cEE4825f57b48ED961",
				label: "sMAG",
			},
		],
		toPool: true,
		rebase: true,
		home_url: "https://app.magnetdao.finance/",
		icon: MagnetImg,
	},
	////////////////
	// {
	// 	id: "klima",
	// 	protocol: "KlimaDAO",
	// 	chain_id: 137,
	// 	tokens: [
	// 		{
	// 			token_contract: "0xb0c22d8d350c67420f06f48936654f567c73e8c8",
	// 			label: "sKLIMA",
	// 			price_chart_url:
	// 				"https://dexscreener.com/polygon/0x5786b267d35f9d011c4750e0b0ba584e1fdbead1",
	// 		},
	// 	],
	// 	rebase: true,
	// 	home_url: "https://www.klimadao.finance/",
	// 	icon: KlimaImg,
	// },
];

export default TokenList;
