import React from "react";
import { Group } from "@visx/group";
import { BarGroup } from "@visx/shape";
import { AxisBottom, AxisRight, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal, scaleLog } from "@visx/scale";
import {
  Glyph as CustomGlyph,
  // GlyphCircle,
  // GlyphCross,
  // GlyphDiamond,
  // GlyphSquare,
  // GlyphStar,
  // GlyphTriangle,
  // GlyphWye,
} from "@visx/glyph";
import moment from "moment";
import { format } from "d3-format";

import OdaoLogo from "./../../images/logo/Odao-logos_white.png";

import { TokenSelect } from "./../meta/tokenSelect.jsx";
import {
  Dialog,
  Button,
  List,
  ListItem,
  ButtonGroup,
  IconButton,
} from "@mui/material";
import { BiRotateRight } from "react-icons/bi";

export type BarGroupProps = {
  width: number;
  height: number;
  //distributions: object;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
};

const defaultMargin = { top: 80, right: 120, bottom: 180, left: 150 };

const shader = (ref, i) => `hsl(145, ${60 - i * 2}%, ${30 + i ** 1.2 * 6.5}%)`;
const gainCellShader = (i) =>
  `hsla(145, 63%, 50%, ${Math.min(Math.floor(0 + 100 * i * 2), 100)}%)`;
const lossCellShader = (i) =>
  `hsla(357, 86%, 56%, ${Math.min(Math.floor(0 + 100 * i * 2), 100)}%)`;

export default function StackedBar({
  width,
  height,
  distributions,
  events = false,
  margin = defaultMargin,
  getTokenData,
  selectedToken,
}: BarGroupProps) {
  ///////////////// Layout sizing /////////////////
  const bottomAxisOffsetLeft = margin.left;
  const key_bars = 5;
  const [dataStyle, setDataStyle] = React.useState("perc");

  /////////////////////////////////////////////////
  ///////////////// Format Data ///////////////////

  const mapped_data = [];
  const latest_data = distributions.slice(-1)[0];
  const oldest_data = distributions[0];

  const field = "portion";
  // [
  //   "constituents",
  //   "min",
  //   "max",
  //   "sum",
  //   "portion",
  //   "avg_value",
  //   "percentile",
  // ];

  for (let i = 0; i < distributions[0].values.length; i++) {
    const mapped_value = {
      x0: distributions[0].values[i]["percentile"],
    };

    distributions.forEach((distribution) => {
      mapped_value[distribution["ts"]] = distribution.values[i][field];
    });

    const newestPoint = distributions.slice(-1)[0].values[i];
    const oldestPoint = distributions[0].values[i];

    mapped_value["min"] = newestPoint["min"];
    mapped_value["max"] = newestPoint["max"];
    mapped_value["sum"] = newestPoint["sum"];
    mapped_value["constituents"] = newestPoint["constituents"];

    mapped_value["token_change_num"] = newestPoint["sum"] - oldestPoint["sum"];
    mapped_value["token_change_perc"] = changePerc("sum");

    mapped_value["constituent_change_num"] =
      newestPoint["constituents"] - oldestPoint["constituents"];
    mapped_value["constituent_change_perc"] = changePerc("constituents");

    mapped_value["portion_change_num"] =
      newestPoint["portion"] - oldestPoint["portion"];
    mapped_value["portion_change_perc"] = changePerc("portion");

    mapped_value["latest_y"] = newestPoint["portion"];

    function changePerc(field) {
      return !oldestPoint[field] && newestPoint[field]
        ? Infinity
        : oldestPoint[field] && !newestPoint[field]
        ? -1
        : !oldestPoint[field] && !newestPoint[field]
        ? 0
        : newestPoint[field] / oldestPoint[field] - 1;
    }

    mapped_data.push(mapped_value);
  }

  /////////////
  const data = mapped_data; //distributions[0].values;
  console.log("dist", distributions, "data", data);

  // accessors
  const getValue = (d) => {
    //console.log("mapD", d);
    return d[field];
  };
  const getBaseline = (d, i, w) => {
    // console.log("mapD2", d);
    return d["x0"];
  };

  const keys = Object.keys(data[0]).filter(
    (is) =>
      ![
        "x0",
        "min",
        "max",
        "sum",
        "token_change_num",
        "token_change_perc",
        "portion_change_num",
        "portion_change_perc",
        "constituents",
        "constituent_change_num",
        "constituent_change_perc",
        "latest_y",
      ].includes(is)
  ); //Object.keys(data[0]); //data.map((item) => item["percentile"]).slice(0, 2);

  //console.log("keys", keys, field, [0, ...data.map((item) => item.x0 / 100)]);

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xBounds = [0, xMax]; //[0, dateScale.bandwidth()] //dateScale.rangeRound([0, xMax])
  const yBounds = [yMax, 0];

  const rangeData = data; //.slice(1);

  const tick_count = data.length; //20;

  // scales
  const valueScale = scaleLinear<number>({
    //scaleLog
    domain: [
      0,
      Math.max(
        ...rangeData.map((d) => Math.max(...keys.map((key) => Number(d[key]))))
      ),
    ],
    range: yBounds,
    padding: 0.2,
  });
  const dateScale = scaleBand<string>({
    domain: keys,
    range: xBounds,
    padding: 0.2,
  });
  const percentileScale = scaleBand<string>({
    domain: rangeData.map((item) => item.x0 || "lol"), //.slice(1),
    range: xBounds,
    //padding: 0.2,
  });
  const valueRangeScale = scaleBand<string>({
    domain: rangeData.map(
      (item) =>
        `${
          item.min > 1
            ? format(".2~s")(item.min.toPrecision(2))
            : item.min < 0.001
            ? 0
            : item.min.toPrecision(2)
        } - ${
          item.max > 1
            ? format(".2~s")(item.max.toPrecision(2))
            : item.max.toPrecision(2)
        }` || "lol"
    ),
    range: xBounds,
    //padding: 0.2,
  });
  const sumScale = scaleBand<string>({
    domain: rangeData.map((item) => item.sum || "lol"),
    range: xBounds,
    //padding: 0.2,
  });
  const changeScale = scaleBand<string>({
    domain: rangeData.map((item) => item.change || "lol"),
    range: xBounds,
    //padding: 0.2,
  });
  const walletScale = scaleBand<string>({
    domain: rangeData.map((item) => item.constituents || "lol"),
    range: xBounds,
    //padding: 0.2,
  });
  const walletChangeScale = scaleBand<string>({
    domain: rangeData.map((item) => item.constituent_change || "lol"),
    range: xBounds,
    //padding: 0.2,
  });

  ///
  const percentScale = scaleLinear<number>({
    domain: rangeData.map((item) => item.x0 / 100),
    range: yBounds,
    //padding: 0.2,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: keys.map(shader), //.reverse(),
  });

  // update scale output dimensions
  dateScale.rangeRound([0, percentileScale.bandwidth()]);

  return width < 10 ? null : (
    <div style={{}}>
      <svg width={width} height={height}>
        {
          // <rect
          //   x={0}
          //   y={0}
          //   width={width}
          //   height={height}
          //   fill={background}
          //   rx={14}
          // />
        }
        <Group
          top={margin.top}
          left={margin.left}
          right={margin.right}
          bottom={margin.bottom}
        >
          <BarGroup
            data={rangeData}
            keys={keys}
            height={yMax}
            x0={getBaseline}
            x0Scale={percentileScale}
            x1Scale={dateScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup, b, arr) => {
                //console.log(barGroups, rangeData, barGroup);
                return (
                  <Group key={b} left={barGroup.x0}>
                    {barGroup.bars.map((bar, i) => {
                      //console.log(b, barGroup.bars.length, bar, i);
                      const offsetLeft =
                        bar.x + 20 - ((i * bar.width) / 2.6) * key_bars; //keys.length;
                      const offsetTop = bar.y;

                      return (
                        <g key={i}>
                          <rect
                            key={i}
                            x={offsetLeft}
                            y={offsetTop}
                            width={(bar.width * key_bars) / 1.15}
                            height={bar.height}
                            fill={bar.color}
                            rx={2}
                            // onClick={() => {
                            //   const { key, value } = bar;
                            //   alert(JSON.stringify(bar));
                            // }}
                          />
                          {
                            //   b === arr.length - 1 ? (
                            //   <CustomGlyph left={0} top={i * 12}>
                            //     <text
                            //       fontSize={14}
                            //       textAnchor="middle"
                            //       dy="0.5em"
                            //       style={{ fill: bar.color }}
                            //     >
                            //       {moment(parseInt(bar.key)).format("MMM-D")}
                            //     </text>
                            //     {
                            //       // <rect
                            //       //   x={-24}
                            //       //   y={10}
                            //       //   width={
                            //       //     bar.width * keys.length +
                            //       //     //offsetLeft +
                            //       //     24 +
                            //       //     100 +
                            //       //     50 * i
                            //       //   }
                            //       //   height={1}
                            //       //   fill={bar.color}
                            //       //   rx={0}
                            //       // />
                            //     }
                            //   </CustomGlyph>
                            // ) : null
                          }
                        </g>
                      );
                    })}
                  </Group>
                );
              })
            }
          </BarGroup>
        </Group>
        <AxisBottom
          ////Percentiles
          top={yMax + margin.top + 5}
          left={bottomAxisOffsetLeft}
          tickComponent={(value) => (
            <XLabelGen
              value={value}
              selectedToken={selectedToken}
              data={data}
              {...{ dataStyle, setDataStyle }}
            />
          )}
          scale={percentileScale}
          hideAxisLine
          tickLabelProps={() => ({
            fill: "#FFFFFF",
            fontSize: 11,
            textAnchor: "middle",
          })}
          numTicks={tick_count}
        />
        <AxisRight
          label="% share"
          labelProps={{ fill: "#FFFFFF", fontSize: 14 }}
          labelOffset={24}
          left={xMax + margin.left + 20}
          top={margin.top}
          hideAxisLine
          scale={valueScale}
          tickFormat={(value, i, arr) => {
            //console.log(value, "sadasdad");
            return Math.floor(value * 100) + "%";
          }}
          tickLabelProps={() => ({
            fill: "#FFFFFF",
            fontSize: 11,
            textAnchor: "middle",
          })}
        />
      </svg>
      {
        /////
        <div
          style={{
            color: "#FFFFFF",
            top: 20,
            left: 100,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              //flexDirection: "column",
              alignItems: "center",
              //justifyContent: "center",
            }}
          >
            <img
              src={OdaoLogo}
              alt="Odao"
              style={{
                width: 80,
                marginLeft: -20,
                marginTop: 0,
                marginBottom: 12,
              }}
            />
            <div style={{ fontWeight: 600, fontSize: 22, marginBottom: 12 }}>
              {""}
            </div>
          </div>
          <TokenSelect {...{ getTokenData, selectedToken }} />
          <div
            style={{
              color: "#FFFFFF",
              top: 40,
              right: 120,
              //position: "absolute",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  backgroundColor: shader(null, 0),
                  borderRadius: 8,
                }}
              ></div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: shader(null, 0),
                  marginLeft: 8,
                }}
              >
                {moment(parseInt(oldest_data.ts)).format("MMM D")}
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginTop: -20,
                  width: 40,
                  height: 40,
                  backgroundColor: shader(null, keys.length - 1),
                  borderRadius: 8,
                }}
              ></div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  color: shader(null, keys.length - 1),
                  marginLeft: 8,
                }}
              >
                {moment(parseInt(latest_data.ts)).format("MMM D")}
              </div>
            </div>
          </div>
          <div style={{ fontWeight: 600, fontSize: 18, marginTop: 20 }}>
            {"Wallets"}
          </div>
          <div
            style={{
              width: 120,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{"#"}</div>
            <div>{`${format(".3~s")(latest_data.point)}`}</div>
          </div>
          <div
            style={{
              width: 120,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{"Δ#"}</div>
            <div
              style={{
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor:
                  latest_data.point / oldest_data.point > 1
                    ? gainCellShader(latest_data.point / oldest_data.point)
                    : lossCellShader(1 - latest_data.point / oldest_data.point),
              }}
            >{`${format("+.3~s")(latest_data.point - oldest_data.point)}`}</div>
          </div>
          <div
            style={{
              width: 120,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{"Δ%"}</div>
            <div
              style={{
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor:
                  latest_data.point / oldest_data.point > 1
                    ? gainCellShader(latest_data.point / oldest_data.point)
                    : lossCellShader(1 - latest_data.point / oldest_data.point),
              }}
            >{`${format("+.1%")(
              latest_data.point / oldest_data.point - 1
            )}`}</div>
          </div>
          <div style={{ fontWeight: 600, fontSize: 18, marginTop: 10 }}>
            {selectedToken.token_label}
          </div>
          <div
            style={{
              width: 120,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{"#"}</div>
            <div>{`${format(".3~s")(latest_data.sum)}`}</div>
          </div>
          <div
            style={{
              width: 120,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{"Δ#"}</div>
            <div>{`${format("+.3s")(latest_data.sum - oldest_data.sum)}`}</div>
          </div>
          <div
            style={{
              width: 120,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{"Δ%"}</div>
            <div>{`${format("+.1%")(
              latest_data.sum / oldest_data.sum - 1
            )}`}</div>
          </div>
        </div>
      }
    </div>
  );
}

const XLabelGen = (props) => {
  const { value, data, selectedToken, dataStyle, setDataStyle } = props;
  //console.log("data", value, data);

  const labelOffsetX = 140;

  const item = data.find((item) => item["x0"] === value.formattedValue);
  const labelOffsetY = 18;
  const percentileMarginBot = 15;
  const portionMarginBot = 28;
  const walletMarginBot = 28;

  ////`${selectedToken.token} (range)`
  ////`${selectedToken.token} (#)`

  const isFirst = value.formattedValue === "0.05";

  return (
    <g>
      <text
        x={value.x}
        y={value.y}
        style={{ fill: value.fill, fontSize: value.fontSize }}
        textAnchor="middle"
      >
        {value.formattedValue === "1.00"
          ? 1
          : `${parseFloat(value.formattedValue).toFixed(2)}`.slice(1)}
      </text>
      {
        //////////// token values
      }
      {isFirst ? (
        <text
          x={value.x - labelOffsetX}
          y={value.y + labelOffsetY + percentileMarginBot}
          style={{ fill: value.fill, fontSize: 13 }}
        >{`${selectedToken.token_label} (range)`}</text>
      ) : null}
      <text
        x={value.x}
        y={value.y + labelOffsetY + percentileMarginBot}
        style={{ fill: value.fill, fontSize: value.fontSize }}
        textAnchor="middle"
      >
        {`${
          item.min > 1
            ? format(".2~s")(item.min.toPrecision(2))
            : item.min < 0.001
            ? 0
            : item.min.toPrecision(2)
        } - ${
          item.max > 1
            ? format(".2~s")(item.max.toPrecision(2))
            : item.max.toPrecision(2)
        }` || "lol"}
      </text>
      {
        //////////
      }
      {isFirst ? (
        <text
          x={value.x - labelOffsetX}
          y={value.y + labelOffsetY * 2 + percentileMarginBot}
          style={{ fill: value.fill, fontSize: 13 }}
        >{`${selectedToken.token_label} (#)`}</text>
      ) : null}
      <text
        x={value.x}
        y={value.y + labelOffsetY * 2 + percentileMarginBot}
        style={{ fill: value.fill, fontSize: value.fontSize }}
        textAnchor="middle"
      >
        {format(".3~s")(item.sum)}
      </text>
      {
        //////////
      }
      {isFirst ? (
        <foreignObject
          width={120}
          height={26}
          x={value.x - labelOffsetX - 4 - 22}
          y={value.y + labelOffsetY * 3 + percentileMarginBot - 18}
        >
          <Button
            disableRipple
            onClick={() => setDataStyle(dataStyle === "num" ? "perc" : "num")}
            style={{
              color: value.fill,
              fontSize: 13,
              height: 20,
              textTransform: "none",
              padding: "1px 4px",
              fontWeight: 400,
            }}
          >
            <BiRotateRight style={{ height: 18, width: 18, marginRight: 4 }} />
            {dataStyle === "num"
              ? `${selectedToken.token_label} (Δ#)`
              : `${selectedToken.token_label} (Δ%)`}
          </Button>
        </foreignObject>
      ) : null}
      <text
        x={value.x}
        y={value.y + labelOffsetY * 3 + percentileMarginBot}
        style={{
          fill: value.fill,
          fontSize: value.fontSize,
          stroke:
            item.token_change_perc >= 0
              ? gainCellShader(item.token_change_perc)
              : lossCellShader(item.token_change_perc * -1),
          strokeWidth: 1.1,
          textAnchor: "middle",
        }}
      >
        {dataStyle === "num"
          ? format("+.3~s")(item.token_change_num)
          : item.token_change_perc === Infinity
          ? "New"
          : format("+.1%")(item.token_change_perc)}
      </text>
      {
        //////////
      }
      {
        //////////////////////// share
      }
      {isFirst ? (
        <foreignObject
          width={120}
          height={26}
          x={value.x - labelOffsetX - 4 - 22}
          y={
            value.y +
            labelOffsetY * 3 +
            percentileMarginBot +
            portionMarginBot -
            18
          }
        >
          <Button
            disableRipple
            onClick={() => setDataStyle(dataStyle === "num" ? "perc" : "num")}
            style={{
              color: value.fill,
              fontSize: 13,
              height: 20,
              textTransform: "none",
              padding: "1px 4px",
              fontWeight: 400,
            }}
          >
            <BiRotateRight style={{ height: 18, width: 18, marginRight: 4 }} />
            {dataStyle === "num" ? `% share (Δ#)` : `% share (Δ%)`}
          </Button>
        </foreignObject>
      ) : null}
      <text
        x={value.x}
        y={value.y + labelOffsetY * 3 + percentileMarginBot + portionMarginBot}
        style={{
          fill: value.fill,
          fontSize: value.fontSize,
          stroke:
            item.portion_change_perc >= 0
              ? gainCellShader(item.portion_change_perc)
              : lossCellShader(item.portion_change_perc * -1),
          strokeWidth: 1.1,
          textAnchor: "middle",
        }}
      >
        {dataStyle === "num"
          ? item.portion_change_perc === Infinity
            ? format("+.1%")(item.latest_y)
            : format("+.1%")(item.portion_change_num)
          : item.portion_change_perc === Infinity
          ? "New"
          : format("+.1%")(item.portion_change_perc)}
      </text>
      {
        //////////
      }
      {
        ////////////////// wallets
      }
      {isFirst ? (
        <text
          x={value.x - labelOffsetX}
          y={
            value.y +
            labelOffsetY * 3 +
            percentileMarginBot +
            portionMarginBot +
            walletMarginBot
          }
          style={{ fill: value.fill, fontSize: 13 }}
        >{`wallets (#)`}</text>
      ) : null}
      <text
        x={value.x}
        y={
          value.y +
          labelOffsetY * 3 +
          percentileMarginBot +
          portionMarginBot +
          walletMarginBot
        }
        style={{
          fill: value.fill,
          fontSize: value.fontSize,
          textAnchor: "middle",
        }}
      >
        {format(".3~s")(item.constituents)}
      </text>
      {
        //////////////
      }
      {isFirst ? (
        <foreignObject
          width={120}
          height={26}
          x={value.x - labelOffsetX - 4 - 22}
          y={
            value.y +
            labelOffsetY * 4 +
            percentileMarginBot +
            portionMarginBot +
            walletMarginBot -
            18
          }
        >
          <Button
            disableRipple
            onClick={() => setDataStyle(dataStyle === "num" ? "perc" : "num")}
            style={{
              color: value.fill,
              fontSize: 13,
              height: 20,
              textTransform: "none",
              padding: "1px 4px",
              fontWeight: 400,
            }}
          >
            <BiRotateRight style={{ height: 18, width: 18, marginRight: 4 }} />
            {dataStyle === "num" ? `wallets (Δ#)` : `wallets (Δ%)`}
          </Button>
        </foreignObject>
      ) : null}
      <text
        x={value.x}
        y={
          value.y +
          labelOffsetY * 4 +
          percentileMarginBot +
          portionMarginBot +
          walletMarginBot
        }
        style={{
          fill: value.fill,
          fontSize: value.fontSize,
          stroke:
            item.constituent_change_perc >= 0
              ? gainCellShader(item.constituent_change_perc)
              : lossCellShader(item.constituent_change_perc * -1),
          textAnchor: "middle",
        }}
      >
        {dataStyle === "num"
          ? format("+.3~s")(item.constituent_change_num)
          : item.constituent_change_perc === Infinity
          ? "New"
          : format("+.1%")(item.constituent_change_perc)}
      </text>
      {
        ////////////////
      }
    </g>
  );
};
