import React from "react";
import { GiTwoCoins } from "react-icons/gi";
import { FaDiscord } from "react-icons/fa";
import { Button } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

import MetamaskLogo from "./../../images/logo/metamask.png";

const Widget = (props) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isCopied, setIsCopied] = React.useState(false);
	const public_address = "0x436CC5DF499d03839f5f6538Eb406C06f6113D0B";

	return (
		<div
			style={{
				position: "absolute",
				right: 12,
				bottom: 12,
				overflow: "hidden",
				display: "flex",
			}}
		>
			<a target="_blank" href="https://discord.gg/zT3e2DBhX7">
				<Button
					onClick={() => {
						window.dataLayer.push({
							event: "open_discord",
						});
					}}
					style={{
						textTransform: "none",
						borderRadius: 8,
						minWidth: 40,
					}}
				>
					<FaDiscord
						style={{ fill: "#FFFFFF", width: 20, height: 20 }}
					/>
				</Button>
			</a>
			<CopyToClipboard
				text={public_address}
				onCopy={() => isOpen && setIsCopied(true)}
			>
				<Button
					onClick={() => {
						window.dataLayer.push({
							event: "open_piglet",
						});
						!isOpen && setIsOpen(true);
					}}
					style={{
						textTransform: "none",
						borderRadius: 8,
						transition: "width 0.3s",
						width: isCopied ? 80 : isOpen ? 280 : 40,
						minWidth: 40,
					}}
				>
					{isOpen ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<img
								src={MetamaskLogo}
								alt="Metamask"
								style={{
									width: 16,
									height: 16,
									marginLeft: 0,
									marginTop: 0,
									marginBottom: 0,
									marginRight: 8,
								}}
							/>

							<div style={{ fontSize: 10, color: "#FFFFFF" }}>
								{isCopied ? "Copied" : public_address}
							</div>
						</div>
					) : (
						<GiTwoCoins
							style={{ fill: "#FFFFFF", width: 20, height: 20 }}
						/>
					)}
				</Button>
			</CopyToClipboard>
		</div>
	);
};

export default Widget;
