import React from "react";
import StackedBar from "./components/charts/stacked_bar.tsx";
import ContributeWidget from "./components/piglet/widget.jsx";
import Footer from "./components/meta/footer.jsx";

import TokenList from "./components/meta/tokenList.jsx";

function App() {
  const [distData, setDistData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedToken, setSelectedToken] = React.useState({
    protocol: { ...TokenList[0] },
    token_contract: "", //TokenList[0].tokens[1].token_contract,
    token_label: "Pooled", //TokenList[0].tokens[1].label,
  });

  async function getTokenData(item, isInit, date_range) {
    if (!isInit) {
      window.dataLayer.push({
        event: "token_select",
        source: "token_select",
        token: item.token_label,
      });
    }

    setIsLoading(true);
    const data = await getDistData(
      item.protocol.protocol,
      item.token_label,
      date_range
    );
    setSelectedToken(item);
    setDistData(data.series);
    setIsLoading(false);
  }

  React.useEffect(() => {
    (async () => {
      getTokenData(selectedToken, true, "10d");
    })();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        //maxWidth: "100%",
        minHeight: "100vh",
        backgroundColor: "#171717", //"E2E2E2", //"hsl(145, 2%, 10%)",
        overflow: "hidden",
        //
      }}
    >
      <div
        style={{
          flex: "1 0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: 1400, height: 740 }}>
          {distData.length ? (
            <StackedBar
              width={1400}
              height={720}
              distributions={distData}
              {...{ getTokenData, selectedToken }}
            />
          ) : null}
        </div>
      </div>
      {
        //<Footer />
      }
      <ContributeWidget />
    </div>
  );
}

const getDistData = async (protocol, token, date_range) => {
  const res = await fetch(
    `/api/charts/dist/?protocol=${protocol}&token=${token}&date_range=${date_range}`,
    {
      method: "POST",
    }
  ).then((res) => res.json());

  if (res.ok) {
    return res.data;
  } else {
    return [];
  }
};

export default App;
