import React from "react";
import {
	Dialog,
	Button,
	List,
	ListItem,
	ButtonGroup,
	IconButton,
} from "@mui/material";
import { FaChartArea } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { VscSettings } from "react-icons/vsc";

import TokenList from "./tokenList.jsx";

const date_ranges = [
	{
		label: "5 days",
		value: "5d",
	},
	{
		label: "10 days",
		value: "10d",
	},
	{
		label: "Max",
		value: "max",
	},
];

const TokenSelect = (props) => {
	const { getTokenData, selectedToken } = props;

	const [isOpen, setIsOpen] = React.useState(false);
	const [settingsOpen, setSettingsOpen] = React.useState(false);

	const [selectedDate, setSelectedDate] = React.useState(date_ranges[1]);

	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				color: "#FFFFFF",
				fontSize: 12,
				marginBottom: 12,
				marginTop: 20,
			}}
		>
			<Button
				style={{
					color: "#FFFFFF",
					textTransform: "none",
					padding: "8px 18px 8px 8px",
					borderRadius: 8,
					boxShadow:
						"rgb(255 255 255 / 1%) 0px 30px 60px -12px inset, rgb(255 255 255 / 20%) 0px 18px 36px -18px inset",
				}}
				onClick={() => setIsOpen(true)}
			>
				<img
					src={selectedToken.protocol.icon}
					alt={selectedToken.token_label}
					style={{
						width: 26,
						height: 26,
						marginLeft: 0,
						marginTop: 0,
						marginBottom: 0,
						marginRight: 12,
						borderRadius: "100%",
						overflow: "hidden",
					}}
				/>
				<div style={{ fontWeight: 600, fontSize: 18 }}>
					{selectedToken.token_label}
				</div>
			</Button>
			<Button
				style={{
					color: "#FFFFFF",
					textTransform: "none",
					padding: "12px 8px",
					borderRadius: 8,
					boxShadow:
						"rgb(255 255 255 / 1%) 0px 30px 60px -12px inset, rgb(255 255 255 / 20%) 0px 18px 36px -18px inset",
					minWidth: 26,
					marginLeft: 4,
				}}
				onClick={() => setSettingsOpen(true)}
			>
				<VscSettings
					style={{
						width: 22,
						height: 22,
					}}
				/>
			</Button>
			<Dialog
				open={settingsOpen}
				onBackdropClick={() => setSettingsOpen(false)}
				PaperProps={{
					style: {
						minWidth: 600,
						padding: 40,
						color: "#FFFFFF",
						backgroundColor: "#171717",
					},
				}}
			>
				<div>
					<div style={{ fontWeight: 600, marginBottom: 12 }}>
						{"Date range"}
					</div>
					<div style={{ display: "flex" }}>
						{date_ranges.map((item) => (
							<Button
								style={{
									color: "#FFFFFF",
									textTransform: "none",
									borderRadius: 8,
									backgroundColor:
										item.value === selectedDate.value
											? "rgb(76, 201, 128)"
											: undefined,
									boxShadow:
										"rgb(255 255 255 / 1%) 0px 30px 60px -12px inset, rgb(255 255 255 / 20%) 0px 18px 36px -18px inset",
									marginLeft: 8,
								}}
								onClick={async () => {
									setSelectedDate(item);
									await getTokenData(
										selectedToken,
										false,
										item.value
									);
									setSettingsOpen(false);
								}}
							>
								{item.label}
							</Button>
						))}
					</div>
				</div>
			</Dialog>
			<Dialog
				open={isOpen}
				onBackdropClick={() => setIsOpen(false)}
				PaperProps={{
					style: {
						minWidth: 700,
						padding: 40,
						color: "#FFFFFF",
						backgroundColor: "#171717",
					},
				}}
			>
				<div style={{}}>
					<Protocols
						color="#fcba03"
						chain_label={"BSC"}
						protocol_list={TokenList.filter(
							(item) => item.chain_id === 56
						)}
						{...{ getTokenData, selectedDate, setIsOpen }}
					/>
					<Protocols
						marginTop
						color="#e84142"
						chain_label={"Avalanche"}
						protocol_list={TokenList.filter(
							(item) => item.chain_id === 43114
						)}
						{...{ getTokenData, selectedDate, setIsOpen }}
					/>
					<Protocols
						marginTop
						color="#7e42f5"
						chain_label={"Polygon"}
						protocol_list={TokenList.filter(
							(item) => item.chain_id === 137
						)}
						{...{ getTokenData, selectedDate, setIsOpen }}
					/>
				</div>
			</Dialog>
		</div>
	);
};

const Protocols = (props) => {
	const {
		protocol_list,
		chain_label,
		color,
		getTokenData,
		selectedDate,
		setIsOpen,
		marginTop,
	} = props;
	const tokenLogoSize = 28;

	return (
		<List
			style={{
				border: "groove 0px " + color,
				borderRadius: 4,
				padding: 20,
				position: "relative",
				marginTop: marginTop ? 20 : 0,
			}}
		>
			<div
				style={{
					display: "none",
					position: "absolute",
					top: -18,
					left: 30,
					fontSize: "0.6em",
					opacity: 0.9,
					fontWeight: 800,
					fontStyle: "italic",
					color: color,
					backgroundColor: "rgb(23, 23, 23)",
					padding: 4,
				}}
			>
				{chain_label.toUpperCase()}
			</div>
			{protocol_list.map((protocol, i) => (
				<ListItem key={i} style={{ marginBottom: 4 }}>
					<div style={{ display: "flex", alignItems: "center" }}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								width: 200,
							}}
						>
							<img
								src={protocol.icon}
								alt={protocol.protocol}
								style={{
									border: `1px solid ${color}`,
									width: tokenLogoSize,
									height: tokenLogoSize,
									marginLeft: 0,
									marginTop: 0,
									marginBottom: 0,
									marginRight: 8,
									borderRadius: "100%",
									overflow: "hidden",
									backgroundColor:
										protocol.iconBackgroundColor,
								}}
							/>
							<div style={{ fontSize: "1.1em", fontWeight: 600 }}>
								{protocol.protocol}
							</div>
						</div>
						<div
							style={{
								//marginLeft: tokenLogoSize + 8,
								marginTop: 4,
								display: "flex",
								flexWrap: "wrap",
							}}
						>
							{protocol.toPool ? (
								<Button
									style={{
										boxShadow:
											"rgb(255 255 255 / 1%) 0px 30px 60px -12px inset, rgb(255 255 255 / 20%) 0px 18px 36px -18px inset",
										textTransform: "none",
										color: "#FFFFFF",
										fontSize: "0.75em",
										padding: "4px 12px",
										border: "none",
										margin: 2,
									}}
									onClick={async () => {
										await getTokenData(
											{
												protocol: {
													...protocol,
												},
												token_contract: "",
												token_label: "Pooled",
											},
											false,
											selectedDate.value
										);
										setIsOpen(false);
									}}
								>
									<div>{"Pooled"}</div>
								</Button>
							) : null}
							{protocol.tokens.map((token, i) => (
								<div key={i} style={{ margin: 2 }}>
									<ButtonGroup
										variant="outlined"
										style={{ border: "none" }}
									>
										<Button
											style={{
												boxShadow:
													"rgb(255 255 255 / 1%) 0px 30px 60px -12px inset, rgb(255 255 255 / 20%) 0px 18px 36px -18px inset",
												textTransform: "none",
												color: "#FFFFFF",
												fontSize: "0.75em",
												padding: "4px 12px",
												border: "none",
											}}
											onClick={async () => {
												await getTokenData(
													{
														protocol: {
															...protocol,
														},
														token_contract:
															token.token_contract,
														token_label:
															token.label,
													},
													false,
													selectedDate.value
												);
												setIsOpen(false);
											}}
										>
											<div>{token.label}</div>
										</Button>
										{
											// <Button
											// 	onClick={() =>
											// 		window
											// 			.open(
											// 				token.price_chart_url,
											// 				"_blank"
											// 			)
											// 			.focus()
											// 	}
											// 	style={{
											// 		padding: "2px 2px",
											// 		boxShadow:
											// 			"rgb(255 255 255 / 1%) 0px 30px 60px -12px inset, rgb(255 255 255 / 20%) 0px 18px 36px -18px inset",
											// 		border: "none",
											// 	}}
											// >
											// 	<FiExternalLink
											// 		style={{
											// 			color: "#FFFFFF",
											// 			height: 12,
											// 			width: 12,
											// 		}}
											// 	/>
											// </Button>
										}
									</ButtonGroup>
								</div>
							))}
						</div>
					</div>
					<a
						style={{
							marginLeft: "auto",
							color: "#FFFFFF", //rgb(117, 211, 156)
						}}
						target="_blank"
						href={protocol.home_url}
					>
						<IconButton
							style={{
								textTransform: "none",
								color: "#FFFFFF",
							}}
						>
							<FiExternalLink style={{ height: 20, width: 20 }} />
						</IconButton>
					</a>
				</ListItem>
			))}
		</List>
	);
};

export { TokenSelect };
